(function (window) {
    let prizeoutSDK = function () {
        this.themeOverride = '';
        this.brandingOverride = '';
        this.visible = false;
        this.endpoint = 'http://localhost:8079/';
        this.listener = null;
        this.dialog = false; // Dialog not created
        this.iframe = false; // Iframe not created
        this.dialogStyle =
            'position:fixed;top:0;left:0;width:100%;height:100vh;background-color:#FFFFFF;z-index:50000;';
        this.boundMessageHandler = this.handleIncomingMessage.bind(this); // To be able to kill the listener
        this.hasInit = false;
        this.isCobrandingEnabled = false;
    };
    prizeoutSDK.prototype.init = function (settings, miniWidgetElement) {
        let scope = this;

        if (scope.hasInit) {
            return;
        }

        scope.hasInit = true;

        this.settings = settings;

        if (this.settings.themeOverride) {
            this.themeOverride = encodeURIComponent(this.settings.themeOverride);
        }

        if (this.settings.brandingOverride) {
            this.brandingOverride = encodeURIComponent(this.settings.brandingOverride);
        }

        if (this.settings.isCobrandingEnabled) {
            this.isCobrandingEnabled = this.settings.isCobrandingEnabled;
        }

        if (miniWidgetElement) {
            this.miniWidgetElement = miniWidgetElement;
            this.settings.miniWidget = true; // Get erased if the user clicks "see more"
        }

        if (document.location.hostname == 'localhost') {
            console.log('SDK settings', settings);
        }

        if (!this.settings.env) {
            this.settings.env = 'sandbox';
        }

        this.encodedSettings = '{}';
        let env_endpoint_prefix = process.env.PARTNER_SDK_ENV;
        let zone_endpoint_prefix = process.env.PARTNER_SDK_ZONE;
        if (this.settings.env == 'sandbox') {
            env_endpoint_prefix = 'sandbox.';
        }
        this.endpoint = 'https://' + env_endpoint_prefix + zone_endpoint_prefix + 'widget-v2.prizeout.com';
        // Localhost compatibility, remove from final template
        if (this.settings.localhost) {
            this.endpoint = 'http://localhost:8079/';
        }
        this.cleanup();
        if (miniWidgetElement) {
            this.settings.miniWidget = true;
            scope.createMiniWidget();
        } else {
            this.settings.miniWidget = false;
            scope.createDialog();
        }

        if (settings.callbacks && settings.callbacks.onInit) {
            settings.callbacks.onInit({
                type: this.settings.miniWidget ? 'mini' : 'full',
            });
        }
    };

    prizeoutSDK.prototype.create_widget = function (element, settings) {
        this.init(settings, element);
    };

    // Create the mini widget
    prizeoutSDK.prototype.createMiniWidget = function () {
        if (this.iframe) {
            this.iframe.remove();
            this.iframe = null;
        }
        this.miniWidgetElement.innerHTML = ''; // Make sure the container is empty
        this.createIframe(this.miniWidgetElement);
    };

    // Resize the mini widget
    prizeoutSDK.prototype.resizeMiniWidget = function (content_height) {
        let scope = this;
        clearInterval(this.resizeDebounce);
        this.resizeDebounce = setTimeout(function () {
            if (scope.miniWidgetElement) {
                scope.miniWidgetElement.style.height = Math.round(content_height) + 'px';
            }
        }, 250);
    };

    // Set the widget to open my giftcards by default
    prizeoutSDK.prototype.toggleOpenMyGiftCards = function () {
        this.settings.open_my_gift_cards = !this.settings.open_my_gift_cards;
    };

    prizeoutSDK.prototype.openToRoute = function (route) {
        this.settings.open_to_route = route;
    };

    // Close my gift cards
    prizeoutSDK.prototype.closeMyGiftCards = function () {
        this.settings.open_my_gift_cards = false;
    };

    prizeoutSDK.prototype.setDefaultSelectedOffer = function (payload) {
        this.settings.defaultSelectedOffer = payload['merchant_name'];
    };

    // Close the mini widget & open the full size one
    prizeoutSDK.prototype.miniWidgetSeeMore = function (data) {
        const seeMoreSettings = {
            settings: this.settings, // pass current state
            mini_session: data,
            partner_id: data['partner_id'],
            env: this.settings.env,
            callbacks: this.settings.callbacks,
            callbackStrings: this.settings.callbackStrings,
        };
        if (!seeMoreSettings.env) {
            console.error('no env specified');
            return;
        }
        if (this.settings.callbacks && this.settings.callbacks.onSeeMoreManual) {
            // only pass  what is needed to render the see more
            this.settings.callbacks.onSeeMoreManual(seeMoreSettings);
        } else {
            if (this.settings.callbacks && this.settings.callbacks.onSeeMore) {
                // only pass  what is needed to render the see more
                this.settings.callbacks.onSeeMore(seeMoreSettings);
            }
            this.cleanup();
            // passing all settings so user can nav back to the mini widget easily. else settings will be overriden
            this.settings['mini_session'] = data;
            this.init(this.settings);
        }
    };

    // Cleanup params between calls
    prizeoutSDK.prototype.cleanup = function () {
        if (this.iframe) {
            this.iframe.remove();
            this.iframe = null;
        }
        this.visible = false;
        this.iframeLoaded = false;
        this.settingsSent = false;
    };
    // Create a dialog
    prizeoutSDK.prototype.createDialog = function () {
        if (this.dialog && this.visible) {
            return this.dialog;
        }
        if (this.dialog && !this.visible) {
            this.dialog.setAttribute('style', this.dialogStyle + 'display:table;');
            if (this.iframe) {
                // Reload the iframe
                this.iframe.setAttribute(
                    'src',
                    this.endpoint +
                        '?env=' +
                        this.settings.env +
                        '&s=' +
                        this.encodedSettings +
                        '&rnd=' +
                        Math.random(),
                );
            }
            this.visible = true;
            return this.dialog;
        }
        this.dialog = document.createElement('div');
        this.dialog.setAttribute('style', this.dialogStyle + 'display:table;');

        let row = document.createElement('div');
        row.setAttribute('style', 'display:table-row;');
        this.dialog.appendChild(row);

        let cell = document.createElement('div');
        cell.setAttribute('style', 'display:table-cell;text-align:center;vertical-align:middle;');
        row.appendChild(cell);

        this.createIframe(cell);

        document.body.appendChild(this.dialog);

        this.visible = true;
    };

    // Create an iframe and connect to it via the SDKs
    prizeoutSDK.prototype.createIframe = function (parent) {
        let scope = this;

        if (this.iframe) {
            return this.iframe;
        }

        // Construct partner url
        let partnerId = (this.settings.publisher && this.settings.publisher.id) || this.settings.partner_id;

        let envString = '';

        if (this.settings.env !== 'prod' && this.settings.env !== 'sandbox') {
            envString = `${this.settings.env}.`;
        }

        if (process.env.PARTNER_SDK_ZONE) {
            envString = envString + `${process.env.PARTNER_SDK_ZONE}`;
        }

        let isCobranded = null;

        //  Determine if partner url is valid
        let checkPartnerUrl = function (url, callback) {
            if (!!scope.isCobrandingEnabled) {
                callback(1);
                return;
            }

            let logoImage = new Image();

            logoImage.onload = function () {
                isCobranded = 1;
                callback(isCobranded);
            };

            logoImage.onerror = function () {
                isCobranded = 0;
                callback(isCobranded);
            };

            logoImage.src = partnerUrl;
        };

        const partnerUrl = `https://${envString}static.prizeout.com/partner-creative/active-logomark/${partnerId}/logomark.png`;

        checkPartnerUrl(
            partnerUrl,
            function (isCobranded) {
                this.iframe = document.createElement('iframe');

                let iframeEndpoint =
                    this.endpoint +
                    '?env=' +
                    this.settings.env +
                    '&s=' +
                    scope.encodedSettings +
                    `&partnerId=${partnerId}&isCobranded=${isCobranded}&isMiniWidget=${
                        this.settings.miniWidget ? 1 : 0
                    }`;

                if (!!this.themeOverride) {
                    iframeEndpoint += `&themeOverride=${this.themeOverride}`;
                }

                if (!!this.brandingOverride) {
                    iframeEndpoint += `&brandOverride=${this.brandingOverride}`;
                }

                this.iframe.setAttribute('src', iframeEndpoint);

                this.iframe.setAttribute('allow', 'clipboard-read; clipboard-write;');

                if (this.settings.classname) {
                    this.iframe.setAttribute('class', this.settings.classname);
                }

                // Partners who nest our widget in a separate iframe can control the size of the widget. In cases where they
                // do not make the widget full screen on ios we can safely remove the safe padding
                if (this.settings.isNestedIFrame) {
                    this.iframe.setAttribute(
                        'style',
                        (this.settings.miniWidget ? '' : 'position:fixed;') +
                            'top:0;left:0;width:100%;height:100%;border:0;box-sizing:border-box;',
                    );
                } else {
                    this.iframe.setAttribute(
                        'style',
                        (this.settings.miniWidget ? '' : 'position:fixed;') +
                            'top:0;left:0;width:100%;height:100%;border:0;padding:env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-left);box-sizing:border-box;',
                    );
                }

                if (this.listening) {
                    window.removeEventListener('message', this.boundMessageHandler, false);
                }

                window.addEventListener('message', this.boundMessageHandler, false);

                this.listening = true;

                this.iframe.onload = function () {
                    scope.iframeLoaded = true;
                    scope.handshake();
                };

                this.iframeParent = parent;

                this.iframeParent.appendChild(this.iframe);
            }.bind(this),
        );
    };

    // Identify to the iframe
    prizeoutSDK.prototype.handshake = function () {
        this.send('settings', this.settings);
        this.settingsSent = true;
        this.hasInit = false;
    };

    // Hide the iframe
    prizeoutSDK.prototype.close = function (response = {}) {
        //this.dialog.setAttribute("style", this.dialogStyle+'display:none;');
        this.dialog.remove();
        this.dialog = null;
        this.iframe = null;
        this.visible = false;
        this.iframeLoaded = false;
        this.settingsSent = false;
        this.hasInit = false;
        if (this.settings.callbacks && this.settings.callbacks.onClose) {
            // Inject the widget type
            response.type = this.settings.miniWidget ? 'mini' : 'full';
            this.settings.callbacks.onClose(response);
        }
        if (this.miniWidgetElement) {
            // Reset the mini widget
            this.init(this.settings, this.miniWidgetElement);
        }
    };

    // Hide the iframe
    prizeoutSDK.prototype.closeMessageOnly = function (response = {}) {
        if (this.settings.callbacks && this.settings.callbacks.onClose) {
            // Inject the widget type
            response.type = this.settings.miniWidget ? 'mini' : 'full';
            this.settings.callbacks.onClose(response);
        }
    };

    // Cashout Failed
    prizeoutSDK.prototype.onCashoutFail = function (response = {}) {
        if (this.settings.callbacks && this.settings.callbacks.onCashoutFail) {
            this.settings.callbacks.onCashoutFail(response);
        }
    };

    // Cashout Completed
    prizeoutSDK.prototype.onCashoutSuccess = function (response = {}) {
        if (this.settings.callbacks && this.settings.callbacks.onCashoutSuccess) {
            this.settings.callbacks.onCashoutSuccess(response);
        }
    };

    // External link message
    prizeoutSDK.prototype.onExternalUrl = function (response = {}) {
        if (this.settings.callbacks && this.settings.callbacks.onExternalUrl) {
            this.settings.callbacks.onExternalUrl(response);
        }
    };

    // Handle incoming messages from the iframe
    prizeoutSDK.prototype.handleIncomingMessage = function (event) {
        let payload;

        try {
            payload = JSON.parse(event.data);
        } catch (e) {
            payload = event.data;
        }

        let rawType = payload.type;
        let dataPayload = payload.payload;

        switch (rawType) {
            case 'hello':
                // Send back the settings
                if (this.iframeLoaded) {
                    this.send('settings', this.settings);
                    this.settingsSent = true;
                } // Otherwise wait until it's loaded
                break;
            case 'close':
                this.close(dataPayload);
                break;
            case 'cashoutFail':
                this.onCashoutFail(dataPayload);
                break;
            case 'cashoutSuccess':
                this.onCashoutSuccess(dataPayload);
                break;
            case 'closeMessageOnly':
                this.closeMessageOnly(dataPayload);
                break;
            case 'widgetHeight':
                this.resizeMiniWidget(dataPayload);
                break;
            case 'widgetSeeMore':
                this.miniWidgetSeeMore(dataPayload);
                break;
            case 'toggleOpenMyGiftCards':
                this.toggleOpenMyGiftCards();
                break;
            case 'closeMyGiftCards':
                this.closeMyGiftCards();
                break;
            case 'setDefaultSelectedOffer':
                this.setDefaultSelectedOffer(dataPayload);
                break;
            case 'getDefaultSelectedOffer':
                this.getDefaultSelectedOffer();
                break;
            case 'externalUrl':
                this.onExternalUrl(dataPayload);
                break;
            case 'openToRoute':
                this.openToRoute(dataPayload);
                break;
        }
    };
    // Send messages to the iframe
    prizeoutSDK.prototype.send = function (type, payload) {
        if (payload && payload.callbacks && typeof payload.callbacks === 'object') {
            payload.callbackStrings = {};
            let callbackKeys = Object.keys(payload.callbacks);
            if (Array.isArray(callbackKeys)) {
                callbackKeys.forEach(function (callbackKey) {
                    if (typeof payload.callbacks[callbackKey] === 'function') {
                        payload.callbackStrings[callbackKey] = payload.callbacks[callbackKey].toString();
                    }
                });
            }
        }

        this.iframe.contentWindow.postMessage(
            {
                type: type,
                payload:
                    payload && typeof payload === 'object' && payload.constructor === Object
                        ? JSON.stringify(payload)
                        : payload,
            },
            '*' /*this.endpoint*/,
        );
    };
    window.prizeoutSDK = new prizeoutSDK();
})(window);
